<template>
  <a-card :bordered="false">
    <a-card class="card" title="水样基本信息" :bordered="false">
      <div slot="extra">
        <a-button @click="backReport" style="margin-right:15px;"><a-icon type="caret-left" /> 返回</a-button>
        <a-button type="primary" @click="updateSample" v-if="permission_edit"><a-icon type="check"/> 保存</a-button>
      </div>
      <a-row class="form-row" :gutter="24">
        <a-col :lg="7" :md="7" :sm="24">
          <a-form-item
                  label="水样编号">
            <a-input
                    style="width:180px"
                    v-model="sampleParam.waterCode"
                    placeholder="请输入水样编号" />
          </a-form-item>
        </a-col>
        <a-col :lg="7" :md="7" :sm="24">
          <a-form-item
                  label="水样类型"
                  style="width:100%">
            <a-select v-model="sampleParam.sampleType"
                      placeholder="请选择"
                      style="width:180px">
              <a-select-option
                      :value="item.value"
                      v-for="item in waterTypeMenuList"
                      :key="item.id"
              >{{ item.description }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="7" :md="7" :sm="24">
          <a-form-item
                  label="采样地点">
            <a-input
                    style="width:180px"
                    v-model="sampleParam.addr"
                    placeholder="请输入采样地点" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-card>
    <a-card class="card" title="水样检测信息" :bordered="false" v-if="!viewSelected">
      <div slot="extra">
        <a-button type="default" style="margin-right:15px" @click="itemAdd(true)" v-if="!showItemAdd"><a-icon type="plus" /> 添加指标</a-button>
        <a-button type="default" style="margin-right:15px" @click="itemAdd(false)" v-if="showItemAdd"><a-icon type="up" /> 收起指标</a-button>
        <a-select v-model="fastSelect"
                  placeholder="请选择"
                  style="width:120px; margin-right:15px;"
                  @change="fastChange"
                  dropdownClassName="select-drop-down"
                  :dropdownMatchSelectWidth="false">
          <a-select-option value="0" :selected="fastSelect === 0">快速选择</a-select-option>
          <a-select-option
                  :value="item.id"
                  :selected="item.id == fastSelect"
                  v-for="item in fastList"
                  :key="item.id"
          >{{ item.name }}</a-select-option>
        </a-select>
        <a-button type="default" style="margin-right:15px" @click="fastEdit()"><a-icon type="edit" /> 指标管理</a-button>
      </div>
      <div v-if="showItemAdd" style="border-bottom:1px solid #ddd; padding:0 0 20px 0; margin-bottom:20px;">
        <div slot="extra">
          <a-radio-group>
            <a-radio-button
                    v-for="item in menuList"
                    :value="item.value"
                    :key="item.id"
                    @click="menuChoose(1,item.value)"
            >{{ item.description }}</a-radio-button>
            <a-radio-button
                    :value="0"
                    :key="0"
                    @click="menuChoose(2,null)"
            >非常规指标</a-radio-button>
          </a-radio-group>
          <a-button type="primary" icon="plus" @click="goEdit(1)" style="margin-left:10px;">管理</a-button>
        </div>

        <s-table
                ref="table"
                size="default"
                rowKey="id"
                :columns="detectColumns"
                :data="loadData"
                :alert="false"
                showPagination="true"
        >

          <span slot="method" slot-scope="text, record, index">
            <template>
            <a-select placeholder="请选择"
                      v-model="record.methodId"
                      style="width:200px"
                      dropdownClassName="select-drop-down"
                      :dropdownMatchSelectWidth="false">
              <a-select-option
                      v-for="option in record.detectionMethodRelaxEntities"
                      :value="option.methodId"
                      :key="option.id"
              >{{ option.method }}</a-select-option>
            </a-select>
            </template>
          </span>

          <span slot="action" slot-scope="text, record">
          <template>
            <a-button @click="addItem(record)" type="primary" disabled v-if="record.isExist===1"><a-icon type="check"/>已选</a-button>
            <a-button @click="addItem(record)" type="default" v-if="record.isExist===0"><a-icon type="check"/>选中</a-button>
          </template>
        </span>
      </s-table>

      </div>
      <a-table
              :columns="columns"
              :dataSource="editList"
              :pagination="false"
      >
        <template slot="serial" slot-scope="text, record, index">
          <template>{{ index + 1 }}</template>
        </template>
        <template v-for="(col, i) in ['detectName', 'unit', 'limitValue']" :slot="col" slot-scope="text, record">
          <a-input
                  :key="col"
                  v-if="record.editable"
                  style="margin: -5px 0"
                  :value="text"
                  :placeholder="columns[i].title"
          />
          <template v-else>{{ text }}</template>
        </template>
        <template slot="dataValue" slot-scope="text, record, index">
          <a-input
                  :placeholder="'检测值'"
                  :value="text"
                  @change="e => itemValueChange(e.target.value, index)"
          />
        </template>
        <template slot="qualified" slot-scope="text, record, index">
          <a-checkbox
                  :checked="text === 1"
                  @change="e => itemQualifiedChange(e.target.checked, index)"></a-checkbox>
          <template v-if="text === 1"> 合格</template>
          <template v-else> 不合格</template>
        </template>
        <template slot="methodName" slot-scope="text, record, index">
          <a-select placeholder="请选择"
                    v-model="editList[index].methodId"
                    style="width:150px"
                    dropdownClassName="select-drop-down"
                    :dropdownMatchSelectWidth="false"
          >
            <a-select-option
                    v-for="option in record.detectionMethodRelaxEntities"
                    :value="option.methodId"
                    :key="option.id"
                    :selected="option.methodId == record.methodId"
            >{{ option.method }}</a-select-option>
          </a-select>
        </template>
        <template slot="action" slot-scope="text, record, index">
          <template>
            <a @click="delItem(record, index)">删除</a>
          </template>
        </template>
      </a-table>
    </a-card>
  </a-card>
</template>

<script>
import { getSampleInfo, updateSampleInfo } from '@/api/quality/project'
import { getMenuList } from '@/api/basic/common'
import { getItemList, getItemPage, getFastList, getFastDetail } from '@/api/quality/item'
import { STable, Ellipsis } from '@/components'

const detectColumns = [
  {
    title: '序号',
    dataIndex: 'index'
  },
  {
    title: '名称',
    dataIndex: 'descChinese'
  },
  {
    title: '检测方法',
    dataIndex: 'method',
    width: '30%',
    scopedSlots: { customRender: 'method' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: '150px',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'AdvancedForm',
  components: {
    STable,
    Ellipsis
  },
  data () {
    this.detectColumns = detectColumns
    return {
      listParam: {
        category: 1,
        type: 1
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        var self = this
        const requestParameters = Object.assign({}, parameter, this.listParam)
        // console.log('loadData request parameters:', requestParameters)
        return getItemList(requestParameters)
                .then(res => {

                  if(res.data.list.length > 0) {
                    for (var i = 0;i<res.data.list.length;i++) {
                      var value = res.data.list[i]
                      // 序号
                      value.index = i + 1

                      if(value.detectionMethodRelaxEntities != null) {
                        for (var entity of value.detectionMethodRelaxEntities) {
                          if(entity.isDefault === 1) {
                            value.methodId = entity.methodId
                          }
                        }
                      }

                      // 检查有没有选中
                      value.isExist = 0
                      for (var j = 0; j < self.editList.length; j++) {
                        if (self.editList[j].detectId === value.id) {
                          value.isExist = 1
                          value.methodId = self.editList[j].methodId
                        }
                      }


                    }
                  }

                  var data = {
                    data: res.data.list,
                    pageNo: parameter.pageNumber,
                    pageSize: parameter.pageSize,
                    totalCount: res.data.total,
                    totalPage: parseInt(res.data.total / parameter.pageSize) + 1
                  }
                  console.log(data)
                  return data
                })
      },

      loading: false,
      memberLoading: false,
      viewSelected: false,
      showItemAdd: false,
      // table
      columns: [
        {
          title: '#',
          dataIndex: 'serial',
          key: 'serial',
          width: '5%',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '检测项',
          dataIndex: 'detectName',
          key: 'detectName',
          width: '15%',
          scopedSlots: { customRender: 'detectName' }
        },
        {
          title: '单位',
          dataIndex: 'unit',
          key: 'type',
          width: '10%',
          scopedSlots: { customRender: 'type' }
        },
        {
          title: '限值',
          dataIndex: 'limitValue',
          key: 'limitValue',
          width: '15%',
          scopedSlots: { customRender: 'limitValue' }
        },
        {
          title: '检测值',
          dataIndex: 'dataValue',
          key: 'dataValue',
          width: '15%',
          scopedSlots: { customRender: 'dataValue' }
        },
        {
          title: '是否合格',
          dataIndex: 'qualified',
          key: 'qualified',
          width: '15%',
          scopedSlots: { customRender: 'qualified' }
        },
        {
          title: '检测方法',
          dataIndex: 'methodName',
          key: 'methodName',
          width: '15%',
          scopedSlots: { customRender: 'methodName' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          width: '10%',
          scopedSlots: { customRender: 'action' }
        }
      ],
      errors: [],
      // 接口参数
      sampleParam: {},
      searchParam: {
        category: '1',
        type: '0'
      },
      // 返回数据
      waterTypeMenuList: [],
      menuList: [],
      itemList: [],
      itemGroup: '1',
      fastList: [],
      fastSelect: '0',
      editList: []
    }
  },
  created () {
    this.permission_edit = localStorage.getItem("permission_edit") == 'true'

    // 读出用户所属单位
    let area = JSON.parse(localStorage.getItem("areaValue"));
    this.centerId = area.depId

    this.getSample()
    this.getMenu()
    this.getFastSettingMenu()
  },
  methods: {
    // 菜单请求
    getMenu () {
      getMenuList({ menu: '指标类型' }).then(response => {
        this.menuList = response.data.list
        this.searchParam.type = response.data.list[0].value
        this.getItem()
      })
    },
    goEdit (id) {
      this.$router.push({
        path: '/test/item',
        query: {
          id: id
        }
      })
    },
    // 接口请求
    getSample () {
      getSampleInfo({ id: this.$route.query.id }).then(response => {
        this.sampleParam = response.data
        if (this.sampleParam.reportDataEntities != null) {
          this.editList = this.sampleParam.reportDataEntities
        }
        for (let i = 0; i < this.editList.length; i++) {
          this.editList[i].methodId = this.editList[i].methodId
        }
        this.getWaterTypeMenu()
      })
    },
    menuChoose (c, v) {
      this.listParam.category = c
      this.listParam.type = v
      this.loadData(this.listParam)
      this.$refs.table.refresh(true)
    },
    getItem () {
      getItemList(this.searchParam).then(response => {
        this.itemList = response.data.list
        for (let i; i < this.itemList.length; i++) {
          for (let j; j < this.itemList[i].detectionMethodRelaxEntities.length; j++) {
            if (this.itemList[i].detectionMethodRelaxEntities[j].isDefault === 1) {
              this.itemList[i].methodId = this.itemList[i].detectionMethodRelaxEntities[j].methodId
            }
          }
        }
        console.log(this.itemList)
      })
    },
    addItem (item) {
      var obj = item
      obj.detectName = obj.descChinese
      obj.detectId = obj.id
      obj.showType = obj.valueType
      obj.qualified = 1

      var isExist = false
      // console.log(obj)
      for (let i = 0; i < this.editList.length; i++) {
        if (this.editList[i].detectId === obj.detectId) {
          isExist = true
        }
      }
      if (isExist) {
        this.$message.error('检测项已存在')
      } else {
        this.editList.push(obj)
        this.$refs.table.refresh(true)
      }
    },
    delItem (record, index) {
      this.editList.splice(index, 1)
      this.$refs.table.refresh(true)
    },
    updateSample () {

      var param = {
        id: this.sampleParam.id,
        addr: this.sampleParam.addr,
        waterCode: this.sampleParam.waterCode,
        sampleType: this.sampleParam.sampleType,
        reportDataEntities: this.updateItem()
      }

      var goodNum = 0
      var badNum = 0
      if (param.reportDataEntities != null) {
        for (var entity of param.reportDataEntities) {
          if(entity.qualified === 1) {
            goodNum++
          } else {
            badNum++
          }
        }
      }
      var content = ''
      if(badNum === 0) {
        content = '所有检测指标均为合格？'
      } else {
        content = badNum + '项检测指标不合格？'
      }

      // 检查是否全部合格
      var self = this
      this.$confirm({
        title: '确认检查',
        content: content,
        cancelText: '取消',
        okText: '确定',
        okType: 'danger',
        onOk () {
          return new Promise((resolve, reject) => {
            updateSampleInfo(param).then(response => {
              self.$message.info('保存成功')

              self.$router.back()
            }).catch((e) => {
              console.log( e)
              self.$message.info('保存失败')
            }).finally(() => {
              resolve()
            })
          })
        }
      })
    },
    // 获取菜单
    getWaterTypeMenu () {
      getMenuList({ menu: '水样类型' }).then(response => {
        this.waterTypeMenuList = response.data.list
      })
    },
    getFastSettingMenu () {
      getFastList({ centerId: this.centerId }).then(response => {
        this.fastList = response.data.list
      })
    },
    // 操作方法
    viewS () {
      this.viewSelected = !this.viewSelected
    },
    itemAdd (b) {
      this.showItemAdd = b
    },
    fastChange () {
      if (this.fastSelect === '0') {
        this.editList = []
        this.editList = this.sampleParam.reportDataEntities
      } else {
        getFastDetail({ id: this.fastSelect }).then(response => {
          this.editList = []
          this.editList = response.data.fastConfigurationInfoEntities
          if (this.editList !== null) {
            for (let i = 0; i < this.editList.length; i++) {
              this.editList[i].dataValue = ''
              this.editList[i].qualified = 1
              this.editList[i].showType = this.editList[i].valueType
            }
          }
          // this.fastSelect = response.data.list[0].id
        })
      }
    },
    fastEdit () {
      this.$router.push({
        path: '/test/setting'
      })
    },
    updateItem () {
      var list = [] // dataValue, detectId, qualified, showType
      for (let i = 0; i < this.editList.length; i++) {
        var obj = {
          dataValue: this.editList[i].dataValue,
          detectId: this.editList[i].detectId,
          qualified: this.editList[i].qualified,
          showType: this.editList[i].showType,
          methodId: this.editList[i].methodId
        }
        list.push(obj)
      }
      return list
    },
    itemValueChange (v, index) {

      var list = this.editList
      // var len = v.split('\r')
      // console.log(len)
      var len = v.split(/\s+/)

      if (len.length === 1) {
        list[index].dataValue = v
        this.editList = []
        this.editList = list
      } else {
        for (let i = 0; i < len.length; i++) {
          len[i] = len[i].split('\t')
        }
        var needLength = this.editList.length - index

        len = len.splice(0, needLength)

        if (len[0].length === 1) {
          for (let j = 0; j < len.length; j++) {
            list[(index + j)].dataValue = len[j][0]
          }
        } else {
          for (let j = 0; j < len.length; j++) {
            list[(index + j)].dataValue = len[j][0]
            list[(index + j)].qualified = parseInt(len[j][1])
          }
        }
        this.editList = []
        this.editList = list
      }
    },
    itemQualifiedChange (v, index) {
      var list = this.editList
      list[index].qualified = v ? 1 : 0
      this.editList = []
      this.editList = list
      // console.log(this.editList)
    },
    backReport () {
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
  .a-hover:hover{
    cursor: pointer;
  }
  .card{
    margin-bottom: 24px;
  }
  .select-drop-down {
    width: auto;
  }
</style>
